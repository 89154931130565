// @flow

import type { Theme } from '@material-ui/core';
import { createTheme, colors as muiColors } from '@material-ui/core';
import { Dropdown } from '../icons/dropdown.js';
import { RoundClose } from '../icons/round-close.js';
import { RoundCheck } from '../icons/round-check.js';
import { text } from './text.js';
import { palette, themeVariables } from './theme';

const size = (px: number) => px / 16 + 'rem';

export const muiTheme: Theme = createTheme({
  typography: {
    fontFamily: themeVariables.fontFamily,
    useNextVariants: true,
    ...text,
  },
  palette,
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(65,65,139, 0.08),0px 1px 1px 0px rgba(65,65,139, 0.06),0px 2px 1px -1px rgba(65,65,139, 0.04)',
    '0px 1px 5px 0px rgba(65,65,139, 0.08),0px 2px 2px 0px rgba(65,65,139, 0.06),0px 3px 1px -2px rgba(65,65,139, 0.04)',
    '0px 1px 8px 0px rgba(65,65,139, 0.08),0px 3px 4px 0px rgba(65,65,139, 0.06),0px 3px 3px -2px rgba(65,65,139, 0.04)',
    '0px 2px 4px -1px rgba(65,65,139, 0.08),0px 4px 5px 0px rgba(65,65,139, 0.06),0px 1px 10px 0px rgba(65,65,139, 0.04)',
    '0px 3px 5px -1px rgba(65,65,139, 0.08),0px 5px 8px 0px rgba(65,65,139, 0.06),0px 1px 14px 0px rgba(65,65,139, 0.04)',
    '0px 3px 5px -1px rgba(65,65,139, 0.08),0px 6px 10px 0px rgba(65,65,139, 0.06),0px 1px 18px 0px rgba(65,65,139, 0.04)',
    '0px 4px 5px -2px rgba(65,65,139, 0.08),0px 7px 10px 1px rgba(65,65,139, 0.06),0px 2px 16px 1px rgba(65,65,139, 0.04)',
    '0px 5px 5px -3px rgba(65,65,139, 0.08),0px 8px 10px 1px rgba(65,65,139, 0.06),0px 3px 14px 2px rgba(65,65,139, 0.04)',
    '0px 5px 6px -3px rgba(65,65,139, 0.08),0px 9px 12px 1px rgba(65,65,139, 0.06),0px 3px 16px 2px rgba(65,65,139, 0.04)',
    '0px 6px 6px -3px rgba(65,65,139, 0.08),0px 10px 14px 1px rgba(65,65,139, 0.06),0px 4px 18px 3px rgba(65,65,139, 0.04)',
    '0px 6px 7px -4px rgba(65,65,139, 0.08),0px 11px 15px 1px rgba(65,65,139, 0.06),0px 4px 20px 3px rgba(65,65,139, 0.04)',
    '0px 7px 8px -4px rgba(65,65,139, 0.08),0px 12px 17px 2px rgba(65,65,139, 0.06),0px 5px 22px 4px rgba(65,65,139, 0.04)',
    '0px 7px 8px -4px rgba(65,65,139, 0.08),0px 13px 19px 2px rgba(65,65,139, 0.06),0px 5px 24px 4px rgba(65,65,139, 0.04)',
    '0px 7px 9px -4px rgba(65,65,139, 0.08),0px 14px 21px 2px rgba(65,65,139, 0.06),0px 5px 26px 4px rgba(65,65,139, 0.04)',
    '0px 8px 9px -5px rgba(65,65,139, 0.08),0px 15px 22px 2px rgba(65,65,139, 0.06),0px 6px 28px 5px rgba(65,65,139, 0.04)',
    '0px 8px 10px -5px rgba(65,65,139, 0.08),0px 16px 24px 2px rgba(65,65,139, 0.06),0px 6px 30px 5px rgba(65,65,139, 0.04)',
    '0px 8px 11px -5px rgba(65,65,139, 0.08),0px 17px 26px 2px rgba(65,65,139, 0.06),0px 6px 32px 5px rgba(65,65,139, 0.04)',
    '0px 9px 11px -5px rgba(65,65,139, 0.08),0px 18px 28px 2px rgba(65,65,139, 0.06),0px 7px 34px 6px rgba(65,65,139, 0.04)',
    '0px 9px 12px -6px rgba(65,65,139, 0.08),0px 19px 29px 2px rgba(65,65,139, 0.06),0px 7px 36px 6px rgba(65,65,139, 0.04)',
    '0px 10px 13px -6px rgba(65,65,139, 0.08),0px 20px 31px 3px rgba(65,65,139, 0.06),0px 8px 38px 7px rgba(65,65,139, 0.04)',
    '0px 10px 13px -6px rgba(65,65,139, 0.08),0px 21px 33px 3px rgba(65,65,139, 0.06),0px 8px 40px 7px rgba(65,65,139, 0.04)',
    '0px 10px 14px -6px rgba(65,65,139, 0.08),0px 22px 35px 3px rgba(65,65,139, 0.06),0px 8px 42px 7px rgba(65,65,139, 0.04)',
    '0px 11px 14px -7px rgba(65,65,139, 0.08),0px 23px 36px 3px rgba(65,65,139, 0.06),0px 9px 44px 8px rgba(65,65,139, 0.04)',
    '0px 11px 15px -7px rgba(65,65,139, 0.08),0px 24px 38px 3px rgba(65,65,139, 0.06),0px 9px 46px 8px rgba(65,65,139, 0.04)',
  ],
  overrides: {
    MuiButtonBase: {
      root: {
        // disabling double-tap to zoom
        touchAction: 'manipulation',
      },
    },
    MuiButton: {
      root: {
        textAlign: 'center',
        fontWeight: 700,
        textTransform: 'none',
        fontSize: size(16),
        cursor: 'pointer',
      },
      contained: {
        boxShadow: 'unset',
        padding: '12px 20px',
      },
      outlined: {
        padding: '12px 20px',
      },
      sizeLarge: {
        padding: '18px 32px',
        fontSize: size(18),
      },
      sizeSmall: {
        padding: '8px 18px',
        fontSize: size(14),
      },
    },
    MuiTableCell: {
      head: {
        ...text.body1,
        fontWeight: '600',
        color: muiColors.grey[600],
        backgroundColor: muiColors.common.white,
        position: 'sticky',
        top: 0,
        padding: '4px 12px 4px 10px',
      },
      body: {
        ...text.body2,
        padding: '20px 12px 20px 10px',
        whiteSpace: 'nowrap',
        fontWeight: '600',
      },
    },
    MuiInputBase: {
      input: {
        fontWeight: 600,
        '&::placeholder': {
          opacity: 0.6,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.84)',
      },
    },
    MuiInputLabel: {
      outlined: {
        position: 'static',
        transform: 'none',
        marginBottom: 10,
        color: 'rgba(0, 0, 0, 0.84)',
        '&$shrink': {
          transform: 'none',
        },
      },
      filled: {
        // Name of the rule
        maxWidth: 'calc(100% - 26px)',
      },
    },
    MuiInputAdornment: {
      positionStart: {
        paddingLeft: 8,
      },
      positionEnd: {
        paddingRight: 8,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius: '5px',
        overflow: 'hidden',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&$focused': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
      underline: {
        '&:before': {
          borderBottom: 'unset',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -9,
        marginRight: 11,
      },
      label: {
        fontSize: '16px !important',
        fontWeight: '600 !important',
      },
    },
    MuiCheckbox: {
      root: {
        padding: 6,
      },
    },

    MuiSwitch: {
      root: {
        padding: '7px 8px',
      },
      track: {
        borderRadius: 12,
        border: '1px solid',
        borderColor: muiColors.grey[300],
        backgroundColor: muiColors.grey[200],
        opacity: 1,
      },
      colorPrimary: {
        '&$checked + $track': {
          backgroundColor: palette.primary.main,
          opacity: 1,
        },
      },
      colorSecondary: {
        '&$checked + $track': {
          backgroundColor: palette.secondary.main,
          opacity: 1,
        },
      },
      switchBase: {
        padding: 4,
      },
    },

    MuiFormHelperText: {
      contained: {
        marginTop: 8,
        marginBottom: 8,
        marginLeft: 0,
        marginRight: 0,
        borderStyle: 'solid',
        borderWidth: 1,
        padding: '8px 12px',
        borderRadius: themeVariables.borderRadius.control,
        color: muiColors.blue[900],
        backgroundColor: muiColors.blue[100],
        borderColor: muiColors.blue[200],
        fontSize: size(14),
        lineHeight: size(18),
        '&$error': {
          color: muiColors.red[900],
          backgroundColor: muiColors.red[100],
          borderColor: muiColors.red[200],
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderWidth: 1.5,
        },
      },
    },
    MuiSelect: {
      select: {
        paddingRight: '45px',
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: 'none',
        fontSize: size(16),
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
      },
    },

    MuiCardActions: {
      root: {
        padding: 16,
      },
      spacing: {
        '& > * + *': {
          marginLeft: 16,
        },
      },
    },

    MuiDialog: {
      paper: {
        '@media (max-width: 48em)': {
          margin: 0,
          width: '100%',
          maxWidth: '100% !important',
          height: '100%',
          maxHeight: 'none !important',
          borderRadius: 0,
        },
      },
    },

    MuiAccordion: {
      root: {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        marginTop: -1,
        marginBottom: -1,
        '&$expanded': {
          marginTop: -1,
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: '20px 0',
      },
    },
  },

  props: {
    MuiButton: {
      variant: 'contained',
      // Material-UI v5 dropped support for "default" color in buttons
      // "primary" is the new default now
      color: 'primary',
      fullWidth: true,
    },

    MuiSelect: {
      IconComponent: () => (
        <Dropdown
          css={{
            top: 'calc(50% - 7px)',
            right: '12px',
            position: 'absolute',
            pointerEvents: 'none',
          }}
        />
      ),
    },

    MuiTextField: {
      fullWidth: true,
    },

    MuiFormControl: {
      fullWidth: true,
    },

    MuiFilledInput: {
      fullWidth: true,
    },

    MuiOutlinedInput: {
      fullWidth: true,
      notched: false,
    },

    MuiSwitch: {
      icon: (
        <span
          css={{
            boxSizing: 'border-box',
            width: 30,
            height: 30,
            backgroundColor: 'white',
            border: '1px solid',
            borderRadius: '50%',
            boxShadow:
              '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#e0e0e0',
          }}
        >
          <RoundClose size={20} fill={palette.primary.main} />
        </span>
      ),
      checkedIcon: (
        <span
          css={{
            borderSizing: 'border-box',
            width: 30,
            height: 30,
            backgroundColor: 'white',
            border: '1px solid',
            borderRadius: '50%',
            boxShadow:
              '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: palette.primary.main,
          }}
        >
          <RoundCheck size={20} fill={palette.primary.main} />
        </span>
      ),
    },
  },
});
