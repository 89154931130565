// @flow

import * as React from 'react';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from '../theme/mui-theme';

type MuiGuard = {| isMuiAttached: boolean |};

const MuiGuardContext: React.Context<MuiGuard> = React.createContext({
  isMuiAttached: false,
});

const MUI_ATTACHED = {
  isMuiAttached: true,
};

export const MuiThemeProvider = (props: {|
  children: React.Node,
|}): React.Node => {
  const ctx = React.useContext(MuiGuardContext);
  // Having that inside dialogs on optimized pathes we can have no MUI
  // we need to check does it already attached or not, to avoid JSS warnings
  if (ctx.isMuiAttached) {
    return props.children;
  }

  return (
    <MuiGuardContext.Provider value={MUI_ATTACHED}>
      <StylesProvider injectFirst={true}>
        <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>
      </StylesProvider>
    </MuiGuardContext.Provider>
  );
};
