// @flow

import dynamic from 'next/dynamic';
import { graphql } from 'react-relay';
import { type NextQuery, nextQuery } from '../controls/relay';
import { MuiThemeProvider } from '../controls/mui-providers';
import { Layout } from '../shared/Layout.js';
import type { appraisalChatQuery } from './__generated__/appraisalChatQuery.graphql';

const AppraisalChat = dynamic(
  () => import('../shared/AppraisalChat').then(module => module.AppraisalChat),
  {
    ssr: false,
    loading: () => <div style={{ height: '100vh' }} />,
  },
);

const AppraisalChatPage = props => (
  <Layout
    root={props.data}
    headerStyle="normal"
    pageWithData={props.data.cms.pageWithData}
    hideMainNavigation={true}
    hideMobileNavigation={true}
    showFooter={false}
  >
    <MuiThemeProvider>
      <AppraisalChat />
    </MuiThemeProvider>
  </Layout>
);

const AppraisalChatP: NextQuery<
  appraisalChatQuery,
  {| lang?: string, uid?: string |},
> = nextQuery(ctx => ({
  query: graphql`
    query appraisalChatQuery($uid: String!, $lang: String!) {
      ...Layout_root @arguments(lang: $lang)
      cms {
        pageWithData(uid: $uid, lang: $lang) {
          ...Layout_pageWithData
        }
      }
    }
  `,
  variables: {
    uid: ctx.query.uid ?? '',
    lang: ctx.query.lang ?? '',
  },
  cacheStrategy: 'none',
  validate: () => {
    if (ctx.query.lang == null) {
      return { type: 'error', statusCode: 404 };
    }
    return null;
  },
}))(AppraisalChatPage);

export default AppraisalChatP;
