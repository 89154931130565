
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/appraisal",
      function () {
        return require("private-next-pages/appraisal.page.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/appraisal"])
      });
    }
  