// @flow

import * as React from 'react';
import type { UserData } from '../../server/UserData';

export type NextQueryProps<T, Q> = {|
  data: {| ...$ElementType<T, 'response'> |},
  variables: {| ...$ElementType<T, 'variables'> |},
  operationName: string,
  userData: {| ...UserData |},
  router: {|
    pathname: string,
    query: Q,
  |},
|};

export type CacheStrategy = 'none' | 'cache-first' | 'cache-only';

type AbstractComponent<T: { ... }> = (props: T) => React.Node;

export type NextQuery<T, Q> = AbstractComponent<NextQueryProps<T, Q>>;

export function nextQuery<
  T: { variables: Object, response: $ReadOnly<Object>, ... },
  Q: { [string]: string, ... },
>(
  init: (
    ctx: {
      pathname: string,
      query: Q,
      asPath: string,
      ...
    },
    userData: {| ...UserData |},
  ) => {
    query: any,
    variables: $ElementType<T, 'variables'>,
    cacheStrategy?: CacheStrategy,
    validate?: (data: $ElementType<T, 'response'>) =>
      | null
      | {|
          type: 'redirect',
          statusCode: 301 | 308 | 302 | 303 | 307,
          location: string,
          page?: string,
        |}
      | {| type: 'error', statusCode: number |},
    ...
  },
): (NextQuery<T, Q>) => NextQuery<T, Q> {
  const fn = Base => {
    const comp = props => {
      return <Base {...props} />;
    };
    comp.getRelay = init;
    return comp;
  };

  return fn;
}
